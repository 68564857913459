import { render, staticRenderFns } from "./ware-details-table.vue?vue&type=template&id=b6f87994&scoped=true"
import script from "./ware-details-table.vue?vue&type=script&lang=js"
export * from "./ware-details-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6f87994",
  null
  
)

export default component.exports